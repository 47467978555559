import React from "react"

import { Layout } from "../../components/Layout"
import { PageContainer } from "../../components/inmyroom/PageContainer"

const seoData = {
  title: "Поздравляем!",
}

export default function SuccessPage() {
  return (
    <Layout seoData={seoData} hasCustomFooter>
      <PageContainer>
        <h1 className="head">Поздравляем!</h1>
        <p className="description">Заявка отправлена успешно, ждите смс с промокодом</p>
      </PageContainer>
    </Layout>
  )
}
